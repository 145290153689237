import React, { FC } from 'react'
import { SectionNewsletterProps } from '@magal/models'
import { styled } from '@magal/styles'
import { Container, SectionWrap } from '@magal/components'
import { NewsletterForm } from '@magal/newsletter'

const Wrapper = styled('div', {
  maxWidth: '$maxWidthS',
  margin: '0 auto',
  backgroundColor: '$red500',
  borderRadius: '$r2',
  display: 'grid',
  gap: '$32',
  gridTemplateColumns: '1fr',
  padding: '$40 $16',
  color: '$beige',
  textAlign: 'center',
  justifyContent: 'center',
  '@lg': {
    padding: '$40',
  },
})

const Title = styled('h2', {
  projectFont: 'caps02',
})

const Subtitle = styled('p', {
  alignSelf: 'end',
  justifySelf: 'center',
  marginTop: '$20',
  projectFont: 'body01',
})

export const SectionNewsletter: FC<SectionNewsletterProps> = ({
  title,
  subtitle,
}) => {
  return (
    <SectionWrap>
      <Container>
        <Wrapper>
          <div>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </div>
          <NewsletterForm
            appearance={'onRed'}
            buttonAppearance={'outlineWhite'}
          />
        </Wrapper>
      </Container>
    </SectionWrap>
  )
}
